<template>
	<div id="id-check-review">
		<div id="cashier-report-filters">
			<FilterDateTime :dateDefaults="dateDefaults" :showExportButton="showExportButton" />
		</div>
		<div v-if="idCheckReport?.rows?.length > 0">
			<div id="tables-container">
				<h2>Id Check Report</h2>
				<table class="idCheck-list" v-for="(item, index) in idCheckReport.rows" :key="index">
					<tr>
						<th>status</th>
						<td>{{ item.status }}</td>
					</tr>
					<tr>
						<th>Provider</th>
						<td>{{ item.provider }}</td>
					</tr>
					<tr>
						<th>Count Attempts</th>
						<td>{{ item.countAttempts }}</td>
					</tr>
					<tr>
						<th>Count Final Status</th>
						<td>{{ item.countFinalStatus }}</td>
					</tr>
				</table>
			</div>
		</div>
		<div v-else>
			<h2 v-if="!serverBusy">No Id Checks found for this date range</h2>
		</div>
	</div>
</template>

<script>
import { onBeforeUnmount } from "vue";
import sharedScripts from "@/dependencies/sharedScripts";
import FilterDateTime from "@/components/FilterDateTime";

export default {
	name: "IdCheckReport",
	components: {
		FilterDateTime,
	},
	props: {
		reporterState: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			timeZoneOffset: new Date().getTimezoneOffset() / 60,
			filters: {},
			dateDefaults: {},
			startDate: null,
			endDate: null,
			idCheckReport: {},
			showExportButton: false,
		};
	},
	watch: {},
	created() {
		let start = new Date();
		start = start.setHours(start.getHours() - this.timeZoneOffset - 168);
		start = new Date(start).toISOString();
		let end = new Date();
		end = end.setHours(end.getHours() - this.timeZoneOffset);
		end = new Date(end).toISOString();
		this.dateDefaults = {
			startDate: start.slice(0, 16),
			endDate: end.slice(0, 16),
		};
		this.eventBus.on("applyFilters", (payload) => {
			let error = false;
			if (!payload.startDate) {
				this.throwDateTimeError("Start Date");
				error = true;
			}
			if (!payload.endDate) {
				this.throwDateTimeError("End Date");
				error = true;
			}
			if (error) return;
			this.startDate = payload.startDate ? payload.startDate.slice(0, 16) : "";
			this.endDate = payload.endDate ? payload.endDate.slice(0, 16) : "";
			this.getIdCheckReport();
		});
		onBeforeUnmount(() => {
			this.eventBus.off("applyFilters");
		});
	},
	mounted() {
		this.applyFilters();
		// this.getIdCheckReport();
	},
	methods: {
		throwDateTimeError(input) {
			this.status.message = `${input} is not complete. Must have both the date and time or be left unset/empty.`;
			this.status.ok = false;
			this.eventBus.emit("updateStatus", this.status);
		},
		applyFilters() {
			let errState = false;
			this.filters = {
				startDate: this.startDate ? this.startDate : this.dateDefaults.startDate,
				endDate: this.endDate ? this.endDate : this.dateDefaults.endDate,
			};

			if (document.getElementById("startDate")?.validity.badInput) {
				this.throwDateTimeError("Start Date");
				errState = true;
			}

			if (document.getElementById("endDate")?.validity.badInput) {
				this.throwDateTimeError("End Date");
				errState = true;
			}

			if (errState) return false;

			this.eventBus.emit("applyFilters", this.filters);
		},
		async getIdCheckReport() {
			// Check component's if server is not already refreshing access token. Return if true.
			if (this.refreshTokenAwait) return;
			this.busyText = "Loading Id Check Results";
			this.serverBusy = true;

			// Check if session needs to be refreshed
			let success = await this.authenticationCheck(this);
			if (success.hasOwnProperty("ok") && !success.ok) {
				this.serverBusy = false;
				this.busyText = "";
				return false;
			}

			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.reporterState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let requestUrl = new URL("/api/v1/report/idcheck", this.rabbitsfootHostUrl);
			let params = requestUrl.searchParams;

			if (this.startDate) params.set("startDate", this.startDate);
			if (this.endDate) params.set("endDate", this.endDate);

			let request = new Request(requestUrl.toString(), {
				method: "GET",
				headers: headerObj,
			});

			try {
				let response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response);

				if (fetchStatus && !fetchStatus.ok) {
					this.serverStatus.serverBusy = false;
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout) this.eventBus.emit("forceLogout");
					return false;
				}

				let dataJson = await response.json();

				this.idCheckReport = dataJson;

				this.serverBusy = false;
				this.serverStatus.busyText = "";
			} catch (e) {
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = e;
				console.error(e);
			}
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
table.idCheck-list {
	margin: 15px auto;
	text-align: center;
	position: relative;
	padding: 15px;
	color: #000;
	background-color: #ddd;
}

.idCheck-list tr.header-row {
	background-color: #525252;
	color: #fff;
}

.idCheck-list tr:nth-child(2n) {
	background-color: #bfbfbf;
}

.filter-options {
	display: flex;
	justify-content: center;
}

th,
td {
	padding: 10px;
}

#tables-container {
    padding-bottom: 30px;
}
</style>
